import { memo, useMemo } from "react";
import clsx from "clsx";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const DatePickerInOutDates = ({
    startDate,
    endDate,
    focused,
    setFocus,
    disabled = {},
    placeholder = { startDate: 'Check-in', endDate: 'Check-out' },
    dateFormat = 'ddd, MMM D',
}) => {
    const isActive = useMemo(() => (!!startDate || !!endDate), [startDate, endDate]);

    return (
        <div className='date-picker-dates'>
            <div
                className={clsx(
                    'date-picker-date',
                    (Boolean(startDate) && 'date-picker-date--filled'),
                    (focused === 'startDate') && 'date-picker-date--focused')
                }
            >
                <input
                    className={'date-picker-date-btn'}
                    type='button'
                    disabled={disabled.startDate}
                    value={startDate?.format(dateFormat) ?? placeholder.startDate}
                    onClick={() => setFocus('startDate')}
                />
            </div>
            <div className='date-picker-arrow'>
                <ArrowForwardIcon
                    sx={{
                        color: isActive ? 'var(--color-black)' : 'var(--color-subtle-grey)'
                    }}
                />
            </div>
            <div
                className={clsx(
                    'date-picker-date',
                    (Boolean(endDate) && 'date-picker-date--filled'),
                    (focused === 'endDate') && 'date-picker-date--focused')
                }
            >
                <input
                    className={'date-picker-date-btn'}
                    type='button'
                    disabled={disabled.endDate}
                    value={endDate?.format(dateFormat) ?? placeholder.endDate}
                    onClick={() => setFocus('endDate')}
                />
            </div>
        </div>
    );
};

export default memo(DatePickerInOutDates);
